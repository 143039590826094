import React, { Component } from "react";
import {
    Row,
    Col,
    Container,
    Form,
    Button,
} from "../common/ReactBootstrapManager";
import SSNDto from "../models/ssnDto";
import { injectIntl, FormattedMessage } from "react-intl";
import LanguageUtils from "../lib/languageUtils";
import UploadInactive from "../img/Upload button inactive.svg";
import UploadActive from "../img/Upload button.svg";
import IconAlert from "../img/Icon Alert Filled.svg";
import ErrorModel from "../models/error";
import { ErrorTypes } from "../constants/constants";
import ErrorPage from "./error/errorPage";

interface ISsnStep2Props {
    onChangeStep: (step: number) => void;
    handleChangeInput: (propName: string, value: string) => void;
    metadata: SSNDto;
    intl: any;
    errorMessage: ErrorModel;
    isValid: boolean;
    hasSignRequest: boolean;
}

const messages = {

    STEP2_TITLE: LanguageUtils.createMessage("STEP2_TITLE"),
    STEP2_PARAGRAPH_LEFT: LanguageUtils.createMessage("STEP2_PARAGRAPH_LEFT"),
    STEP2_PARAGRAPH_MIDDLE: LanguageUtils.createMessage("STEP2_PARAGRAPH_MIDDLE"),
    STEP2_PARAGRAPH_RIGHT: LanguageUtils.createMessage("STEP2_PARAGRAPH_RIGHT"),
    STEP2_LABEL: LanguageUtils.createMessage("STEP2_LABEL"),
    PASSWORD_PLACEHOLDER: LanguageUtils.createMessage("PASSWORD_PLACEHOLDER"),
    OTP_VALIDATION: LanguageUtils.createMessage("OTP_VALIDATION"),
    OTP_VALIDATION_LINK: LanguageUtils.createMessage("OTP_VALIDATION_LINK"),
    STEP2_PARAGRAPH_HAS_SIGN_REQUEST: LanguageUtils.createMessage("STEP2_PARAGRAPH_HAS_SIGN_REQUEST")
};

class SsnStep2 extends Component<ISsnStep2Props, any> {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _onChangeStep = (e: any) => {
        const { onChangeStep } = this.props;
        e.preventDefault();
        onChangeStep(3);
    }

    render() {
        const {
            handleChangeInput,
            metadata,
            onChangeStep,
            errorMessage,
            isValid,
            hasSignRequest
        } = this.props;
        const validation = errorMessage.type === ErrorTypes.unauthorized && isValid === true;
        const generalErrors = errorMessage.type === ErrorTypes.internalServerError || errorMessage.type === ErrorTypes.conflict;
        const { formatMessage } = this.props.intl;
        const isEnabled = metadata.otp !== undefined && metadata.otp.length === 6;
        const cssButton = isEnabled && !validation ? UploadActive : UploadInactive;
        const hasSignRequestValidation = errorMessage.type === ErrorTypes.hasAlreadySigned && hasSignRequest === true;
        const cssFormControl = validation ? "inputInvalid" : "inputActive"

        return (
            <Container fluid className="headerBgBlue maxWidth">
                {hasSignRequestValidation ? (
                    <section>
                        <p className="login-parag mt-5 pt-5 pb-3">
                            <FormattedMessage {...messages.STEP2_PARAGRAPH_HAS_SIGN_REQUEST} />
                        </p>
                    </section>
                ) : generalErrors ? (
                    <ErrorPage />
                ) : (
                            <section>
                                <h2 className="headings-title font-weight-bold justify-content-center text-center pt-4 pb-3">
                                    <FormattedMessage {...messages.STEP2_TITLE} />
                                </h2>

                                <p className="login-parag pt-3 pb-3">
                                    <FormattedMessage {...messages.STEP2_PARAGRAPH_LEFT} />
                                    <span>
                                        <Button
                                            variant="link"
                                            type="button"
                                            className="p-0 login-parag underline"
                                            onClick={() => onChangeStep(1)}
                                        >
                                            <FormattedMessage {...messages.STEP2_PARAGRAPH_MIDDLE} />
                                        </Button>
                                    </span>
                                    <span>
                                        <FormattedMessage {...messages.STEP2_PARAGRAPH_RIGHT} />
                                    </span>
                                </p>

                                <div className="mt-2 mx-auto mxWidth">
                                    <Form onSubmit={this._onChangeStep}>
                                        <Form.Label
                                            className="label-text"
                                        >
                                            <FormattedMessage {...messages.STEP2_LABEL} />
                                        </Form.Label>
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={10}
                                            lg={10}
                                            className={`${cssFormControl} d-flex h-100 align-items-center`}
                                        >
                                            <Form.Control
                                                type="string"
                                                name="otp"
                                                autoComplete="off"
                                                placeholder={formatMessage(messages.PASSWORD_PLACEHOLDER)}
                                                value={metadata.otp}
                                                onChange={(e: any) =>
                                                    handleChangeInput(
                                                        "otp",
                                                        e.target.value
                                                    )
                                                }
                                            >
                                            </Form.Control>
                                            <Button
                                                type="button"
                                                variant="light"
                                                className="p-0"
                                                aria-hidden="true"
                                                id="contained-button-file"
                                                onClick={() => onChangeStep(3)}
                                                disabled={!isEnabled}
                                            >
                                                <img
                                                    src={cssButton}
                                                    alt="upload"
                                                />
                                            </Button>
                                        </Col>
                                    </Form>
                                </div>
                                {validation && (
                                    <Row className="mt-2 mx-auto mxWidth">
                                        <Col className="pl-1 overlay" xs={12} sm={12} md={12}>
                                            <span className="align-top">
                                                <img
                                                    src={IconAlert}
                                                    alt="icon-alert"
                                                    className="mx-auto"
                                                />
                                            </span>
                                            <span className="pl-2 mx-auto validationMessage">
                                                <FormattedMessage {...messages.OTP_VALIDATION} />

                                                <Button
                                                    variant="link"
                                                    type="button"
                                                    className="p-0 validationMessage underline"
                                                    onClick={() => onChangeStep(1)}
                                                >
                                                    <FormattedMessage {...messages.OTP_VALIDATION_LINK} />
                                                </Button>
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </section>
                        )}
            </Container>
        );
    }
}

export default injectIntl(SsnStep2);
