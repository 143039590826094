import keyMirror from "keymirror";

const SSNActionNames = keyMirror({
    SEND_SMS_WITH_OTP: null,
    SEND_SMS_WITH_OTP_SUCCESS: null,
    SEND_SMS_WITH_OTP_ERROR: null,
    LOG_IN: null,
    LOG_IN_SUCCESS: null,
    LOG_IN_ERROR: null,
    SIGN_DOCUMENT: null,
    SIGN_DOCUMENT_SUCCESS: null,
    SIGN_DOCUMENT_ERROR: null,
    SET_ERROR: null,
    RESET_ERROR: null
});

export default SSNActionNames;
