import React, { Component } from "react";
import {
	Col,
	Container,
	Form,
	Button,
} from "../common/ReactBootstrapManager";
import SSNDto from "../models/ssnDto";
import { injectIntl, FormattedMessage } from "react-intl";
import LanguageUtils from "../lib/languageUtils";
import UploadInactive from "../img/Upload button inactive.svg";
import UploadActive from "../img/Upload button.svg";
import IApplicationState from "../setup/IApplicationState";
import ssnSelectors from "../selectors/ssnSelectors";
import { connect } from "react-redux";
import LoadingWrapper from "../common/loadingWrapper";

interface ISsnStep3Props {
	onChangeStep: (step: number) => void;
	handleChangeInput: (propName: string, value: string) => void;
	metadata: SSNDto;
	intl: any;
	loadingSignDocument: boolean;
}

const messages = {
	STEP3_TITLE: LanguageUtils.createMessage("STEP3_TITLE"),
	STEP3_PARAGRAPH: LanguageUtils.createMessage("STEP3_PARAGRAPH"),
	STEP3_LABEL: LanguageUtils.createMessage("STEP3_LABEL"),
	SSN_PLACEHOLDER: LanguageUtils.createMessage("SSN_PLACEHOLDER"),
	INPUT_SSN_WAITING_PAGE_TITLE: LanguageUtils.createMessage("INPUT_SSN_WAITING_PAGE_TITLE"),
	INPUT_SSN_WAITING_PAGE_PARAGRAPH: LanguageUtils.createMessage("INPUT_SSN_WAITING_PAGE_PARAGRAPH"),
};

class SsnStep3 extends Component<ISsnStep3Props, any> {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onChangeStep = (e: any) => {
		const { onChangeStep } = this.props;
		e.preventDefault();
		onChangeStep(4);
	}

	render() {
		const {
			handleChangeInput,
			metadata,
			onChangeStep,
			loadingSignDocument,
		} = this.props;
		const { formatMessage } = this.props.intl;
		const isEnabled = metadata.ssn.length === 11;
		const cssButton = isEnabled ? UploadActive : UploadInactive;
		const waitingSSNValidation = loadingSignDocument === true;

		return (
			<Container fluid className="headerBgBlue maxWidth">
				{waitingSSNValidation ? (
					<>
						<section>
							<h2 className="headings-title font-weight-bold justify-content-center text-center pt-4 pb-3">
								<FormattedMessage {...messages.INPUT_SSN_WAITING_PAGE_TITLE} />
							</h2>

							<p className="login-parag pt-2 pb-2">
								<FormattedMessage {...messages.INPUT_SSN_WAITING_PAGE_PARAGRAPH} />
							</p>
						</section>
						<LoadingWrapper
							loading={
								loadingSignDocument
							}
							size={70}
							className="loadingWrapper overlay"
						>
						</LoadingWrapper>
					</>
				) : (
						<section>
							<h2 className="headings-title font-weight-bold justify-content-center text-center pt-4 pb-3">
								<FormattedMessage {...messages.STEP3_TITLE} />
							</h2>

							<p className="login-parag pt-3 pb-3">
								<FormattedMessage {...messages.STEP3_PARAGRAPH} />
							</p>

							<div className="mt-2 mx-auto mxWidth">
								<Form onSubmit={this._onChangeStep}>
									<Form.Label
										className="label-text"
									>
										<FormattedMessage {...messages.STEP3_LABEL} />
									</Form.Label>
									<Col
										xs={12}
										sm={12}
										md={10}
										lg={10}
										className="inputActive d-flex h-100 align-items-center"
									>
										<Form.Control
											type="text"
											name="ssn"
											autoComplete="off"
											placeholder={formatMessage(messages.SSN_PLACEHOLDER)}
											value={metadata.ssn}
											onChange={(e: any) =>
												handleChangeInput(
													"ssn",
													e.target.value
												)
											}
										>
										</Form.Control>
										<Button
											type="button"
											variant="light"
											className="p-0"
											aria-hidden="true"
											id="contained-button-file"
											onClick={() => onChangeStep(4)}
											disabled={!isEnabled}
										>
											<img
												src={cssButton}
												alt="upload"
											/>
										</Button>
									</Col>
								</Form>
							</div>
						</section>
					)}
			</Container>
		);
	}
}

const mapStoreToProps = (store: IApplicationState) => {
	return {
		loadingSignDocument: ssnSelectors.loadingSignDocument(store),
	};
};


export default injectIntl(
	connect(mapStoreToProps)(SsnStep3)
);

