import ISsnReducer from "../reducers/interfaces/iSsnReducer";

const ssn = (store: any) => store.get("jiraMetadata").toJS() as ISsnReducer;

const ssnSelectors = {
	loadingSms: (store: any) => ssn(store).loadingSms,
	loadingLogin: (store: any) => ssn(store).loadingLogin,
	loadingSignDocument: (store: any) => ssn(store).loadingSignDocument,
	jiraMetadata: (store: any) => ssn(store).jiraMetadata,
	token: (store: any) => ssn(store).token,
	errorMessage: (store: any) => ssn(store).errorMessage,
};

export default ssnSelectors;

