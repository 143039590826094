import SSNActionNames from "../constants/ssnActionNames";
import IAction from "./iAction";
import Error from "../models/error";
import SSNDto from "../models/ssnDto";
import DocumentSignDto from "../models/documentSignInputDto";
import Token from "../models/token"; 
import AuthToken from "../models/token";

const sendSmsWithOtp = (phoneNumber: string): IAction => {
	return {
		type: SSNActionNames.SEND_SMS_WITH_OTP,
		payload: phoneNumber
	};
};

const sendSmsWithOtpSuccess = (jiraMetadata: SSNDto): IAction => {
	return {
		type: SSNActionNames.SEND_SMS_WITH_OTP_SUCCESS,
		payload: jiraMetadata
	};
};

const sendSmsWithOtpError = (error: Error): IAction => {
	return {
		type: SSNActionNames.SEND_SMS_WITH_OTP_ERROR,
		payload: error
	};
};

const logIn = (otp: string, privateEmail: string): IAction => {
	return {
		type: SSNActionNames.LOG_IN,
		payload: { otp, privateEmail }
	};
};

const logInSuccess = (token: Token): IAction => {
	return {
		type: SSNActionNames.LOG_IN_SUCCESS,
		payload: token
	};
};

const logInError = (error: Error): IAction => {
	return {
		type: SSNActionNames.LOG_IN_ERROR,
		payload: error
	};
};

const signDocument = (documentSignInput: DocumentSignDto, authToken: AuthToken): IAction => {
	return {
		type: SSNActionNames.SIGN_DOCUMENT,
		payload: { documentSignInput, authToken }
	}
}

const signDocumentSuccess = (): IAction => {
	return {
		type: SSNActionNames.SIGN_DOCUMENT_SUCCESS,
	}
}

const signDocumentError = (error: Error): IAction => {
	return {
		type: SSNActionNames.SIGN_DOCUMENT_ERROR,
		payload: error
	}
}

const setError = (errorMessage: Error): any => {
    return {
        type: SSNActionNames.SET_ERROR,
        payload: errorMessage,
    };
};

const resetError = (): any => {
    return {
        type: SSNActionNames.RESET_ERROR,
    };
};

export default {
	sendSmsWithOtp,
	sendSmsWithOtpSuccess,
	sendSmsWithOtpError,
	logIn,
	logInSuccess,
	logInError,
	signDocument,
	signDocumentSuccess,
	signDocumentError,
	setError,
	resetError
};
