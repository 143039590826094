import React, { Component } from "react";
import {
    Col,
    Container,
    Form,
    Button,
} from "../common/ReactBootstrapManager";
import UploadActive from "../img/Upload button.svg";
import { FormattedMessage } from "react-intl";
import LanguageUtils from "../lib/languageUtils";
import UrlUtils from "../lib/urlUtils";
import { withRouter, RouteComponentProps } from "react-router";
import ErrorPage from "./error/errorPage";

interface ISsnStep1Props {
    onChangeStep: (step: number) => void;
}

type ISSNStep1WithRouterProps = ISsnStep1Props & RouteComponentProps<{}>;

const messages = {
    STEP1_TITLE: LanguageUtils.createMessage("STEP1_TITLE"),
    STEP1_PARAGRAPH: LanguageUtils.createMessage("STEP1_PARAGRAPH"),
    LOGIN_INPUT: LanguageUtils.createMessage("LOGIN_INPUT"),
};

class SsnStep1 extends Component<ISSNStep1WithRouterProps, any> {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _onChangeStep = (e: any) => {
        const { onChangeStep } = this.props;
        e.preventDefault();
        onChangeStep(2);
    }

    render() {
        const {
            onChangeStep,
            location,
        } = this.props;

        const params = UrlUtils.getParams(location.search);

        let encodedPhoneNumber = params.token ? params.token : "";
        let phoneNumber = Array.from(encodedPhoneNumber).map(function (v, i) {
            return encodedPhoneNumber.charCodeAt(i) - i - 97;
        }).join('');

        return (
            <Container fluid className="headerBgBlue maxWidth">
                {!phoneNumber ?
                    (
                        <>
                            { UrlUtils.goto("error")}
                            <ErrorPage />
                        </>
                    ) : (
                        <section>
                            <h2 className="headings-title font-weight-bold justify-content-center text-center pt-4 pb-2">
                                <FormattedMessage {...messages.STEP1_TITLE} />
                            </h2>

                            <p className="login-parag pt-3 pb-3">
                                <FormattedMessage {...messages.STEP1_PARAGRAPH} />
                            </p>

                            <div>
                                <div className="mt-2 mxWidth mx-auto">
                                    <Form onSubmit={this._onChangeStep}>
                                        <Form.Label
                                            className="label-text"
                                        >
                                            <FormattedMessage {...messages.LOGIN_INPUT} />
                                        </Form.Label>
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={10}
                                            lg={10}
                                            className="inputActive d-flex h-100 align-items-center"
                                        >
                                            {params && phoneNumber &&
                                                <Form.Control
                                                    type="text"
                                                    name="token"
                                                    readOnly
                                                    value={phoneNumber}
                                                >
                                                </Form.Control>}
                                            {params && phoneNumber &&
                                                <Button
                                                    type="button"
                                                    variant="light"
                                                    className="p-0"
                                                    aria-hidden="true"
                                                    id="contained-button-file"
                                                    onClick={() => onChangeStep(2)}
                                                >
                                                    <img
                                                        src={UploadActive}
                                                        alt="upload"
                                                    />
                                                </Button>
                                            }
                                        </Col>
                                    </Form>
                                </div>
                            </div>
                        </section>
                    )}
            </Container>
        );
    }
}

export default withRouter(SsnStep1);
