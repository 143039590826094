import { Map, List } from "immutable";
import IImmutableSsnReducer from "./interfaces/immutable/iImmutableSsnReducer";
import SsnActionNames from "../constants/ssnActionNames";
import SSNDto from "../models/ssnDto";
import AuthToken from "../models/token";
import ErrorModel from "../models/error";

const initialState = Map({
    jiraMetadata: new SSNDto(),
    loadingSms: false,
    loadingLogin: false,
    loadingSignDocument: false,
    loadingIssue: false,
    token: new AuthToken(),
    errors: List(),
    errorMessage: new ErrorModel(),
}) as IImmutableSsnReducer;

const ssnReducer = (
    state: IImmutableSsnReducer = initialState,
    action: any
) => {
    switch (action.type) {
        case SsnActionNames.SEND_SMS_WITH_OTP: {
            return state.withMutations(state => {
                state.set("loadingSms", true);
            });
        }

        case SsnActionNames.SEND_SMS_WITH_OTP_SUCCESS: {
            return state.withMutations(state => {
                state.set("loadingSms", false);
                state.set("jiraMetadata", new SSNDto(action.payload));
            });
        }

        case SsnActionNames.SEND_SMS_WITH_OTP_ERROR: {
            return state.withMutations(state => {
                state.set("loadingSms", false);
                state.set("errorMessage", action.payload);
            });
        }

        case SsnActionNames.LOG_IN: {
            return state.withMutations(state => {
                state.set("loadingLogin", true);
                state.set("jiraMetadata", new SSNDto());
            });
        }

        case SsnActionNames.LOG_IN_SUCCESS: {
            return state.withMutations(state => {
                state.set("loadingLogin", false);
                state.set("token", AuthToken.mapFromApi(action.payload));
            });
        }

        case SsnActionNames.LOG_IN_ERROR: {
            return state.withMutations(state => {
                state.set("loadingLogin", false);
                state.set("errorMessage", action.payload);
            });
        }

        case SsnActionNames.SIGN_DOCUMENT: {
            return state.withMutations(state => {
                state.set("loadingSignDocument", true);
                state.set("jiraMetadata", new SSNDto());
            });
        }

        case SsnActionNames.SIGN_DOCUMENT_SUCCESS: {
            return state.withMutations(state => {
                state.set("loadingSignDocument", false);
            });
        }

        case SsnActionNames.SIGN_DOCUMENT_ERROR: {
            return state.withMutations(state => {
                state.set("loadingSignDocument", false);
                state.update("errors", errors =>
                    errors.push({
                        error: action.payload,
                        time: new Date()
                    })
                );
            });
        }

        case SsnActionNames.SET_ERROR: {
            return state.set("errorMessage", action.payload);
        }

        case SsnActionNames.RESET_ERROR: {
            return state.set("errorMessage", new ErrorModel());
        }

        default: {
            return state;
        }
    }
};
export default ssnReducer;
