class DocumentSignInputDto {
  issueKey: string;
  candidateSsn: string;

  constructor(
    documentSignInput: {
      issueKey: string;
      candidateSsn: string;
      
    } = {
        issueKey: "",
        candidateSsn: ""
      }
  ) {
    this.issueKey = documentSignInput.issueKey;
    this.candidateSsn = documentSignInput.candidateSsn;
  }
}
export default DocumentSignInputDto;
