import React from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import { hot } from "react-hot-loader/root";
import theme from "./theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Root from "./root";
import RouteConstants from "./constants/routeConstants";
import SSNPage from "./components/SSNPage";
import ErrorPage from "./components/error/errorPage";

const AppRouter: React.StatelessComponent<{}> = () => (
  <MuiThemeProvider theme={theme}>
    <HashRouter>
      <Switch>
        <Root>
          <Switch>
          <Route exact path={`/${RouteConstants.Pages.SSNPage}`} component={SSNPage} />
            <Route exact path={`/${RouteConstants.Pages.Error}`} component={ErrorPage} />
            <Redirect from="*" to={`/${RouteConstants.Pages.Error}`} />
          </Switch>
        </Root>
      </Switch>
    </HashRouter>
  </MuiThemeProvider>
);

export default hot(AppRouter);
