import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import ConfigActions from "../actions/configActions";
import ConfigSelectors from "../selectors/configSelectors";
import IApplicationState from "../setup/IApplicationState";
import { IntlProvider } from "react-intl";
import LanguageUtils from "../lib/languageUtils";
import moment from "moment";

interface ConfigProps {
    children: any;
    isLoaded: boolean;
    fetchConfig: any;
}

class Config extends React.Component<ConfigProps> {
    componentDidMount() {
        this.props.fetchConfig();
    }

    render() {
        const { children, isLoaded } = this.props;
        const usedLanguage = LanguageUtils.getLanguage();
        const simpleFormLanguage = LanguageUtils.getSimpleFormLanguage(usedLanguage);
        moment.locale(simpleFormLanguage);

        if (!isLoaded) {
            return <div />;
        }

        return (
            <IntlProvider key={usedLanguage} locale={simpleFormLanguage} messages={LanguageUtils.getMessages(usedLanguage)}>
                {children}
            </IntlProvider>)
            ;
    }
    // render() {
    //     const { children, isLoaded } = this.props;
    //     if (!isLoaded) {
    //         return <div />;
    //     }

    //     return children;
    // }
}

const mapStoreToProps = (store: IApplicationState) => {
    return {
        isLoaded: ConfigSelectors.isLoaded(store)
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchConfig: () => dispatch(ConfigActions.fetchConfig())
});

export default connect(
    mapStoreToProps,
    mapDispatchToProps
)(Config);
