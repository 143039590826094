import React, { Component } from "react";
import LanguageUtils from "../../lib/languageUtils";
import { FormattedMessage } from "react-intl";
import { Container } from "../../common/ReactBootstrapManager";
import { ContactLink } from "../../constants/constants";

const messages = {
    NOT_FOUND_PAGE_TITLE: LanguageUtils.createMessage("NOT_FOUND_PAGE_TITLE"),
    NOT_FOUND_PAGE_PARAGRAPH: LanguageUtils.createMessage("NOT_FOUND_PAGE_PARAGRAPH"),
    CONTACT_MAIL: LanguageUtils.createMessage("CONTACT_MAIL"),
};

class ErrorPage extends Component<any, any> {

    render() {
        return (
            <Container fluid className="headerBgBlue maxWidth flex-root">
                <section className="maxWidth">
                    <h2 className="headings-title font-weight-bold justify-content-center text-center pt-5 pb-3">
                        <FormattedMessage {...messages.NOT_FOUND_PAGE_TITLE} />
                    </h2>

                    <p className="login-parag pt-3 pb-3">
                        <FormattedMessage {...messages.NOT_FOUND_PAGE_PARAGRAPH} />
                        <a className="login-parag"
                            href={`mailto:${ContactLink.contactMail}`}
                        >
                            <FormattedMessage {...messages.CONTACT_MAIL} />
                        </a>
                    </p>
                </section>
            </Container>
        );
    }
}

export default (ErrorPage);
