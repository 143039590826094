// Client translation
import nbMessages from "../locale/nb-NO.json";
import daMessages from "../locale/da-DA.json";

// Moment translation. We have to do this in order to load locales
import nb from "moment/locale/nb"; // eslint-disable-line no-unused-vars
import da from "moment/locale/da"; // eslint-disable-line no-unused-vars

const messages = {
  "nb-NO": nbMessages,
  "da-DA": daMessages
};

export const languageMap = {
  no: "nb-NO",
  nb: "nb-NO",
  "no-nb": "nb-NO",
  "nb-no": "nb-NO",
  "nn-no": "nb-NO",
  da: "da-DA",
  "da-DA": "da-DA"
};

export const DEFAULT_LANGUAGE = "nb-NO";

class LanguageUtils {
  static getLanguage(user) {
    if (!user) {
      return DEFAULT_LANGUAGE;
    }

    return LanguageUtils.getSupportedLanguage(user.locale);
  }

  static getLanguageAcceptString() {
    const language = this.getLanguage();

    return `${language}`;
  }

  static getSimpleFormLanguage(code) {
    if (typeof code === "string") {
      const dash = code.indexOf("-");

      if (dash === -1) {
        return code;
      } else {
        const chunks = code.split("-");

        return chunks[0];
      }
    }

    return "";
  }

  static getSupportedLanguage(code) {
    if (typeof code === "string") {
      code = code.toLowerCase();

      if (languageMap[code]) {
        return languageMap[code];
      }
    }

    return DEFAULT_LANGUAGE;
  }

  static getResourceFormLanguage() {
    const regex = /(.*)[_](.*)/;
    const language = this.getLanguage();
    let m;

    if ((m = regex.exec(language.replace("-", "_"))) !== null) {
      return `${m[1]}_${m[2].toUpperCase()}`;
    }

    return undefined;
  }

  static getMessages(code) {
    return messages[code];
  }

  static createMessage(defaultText, id, description) {
    const correctId = id ? id : defaultText.toUpperCase().replace(/\s/g, "_");

    return {
      defaultMessage: `${defaultText} (need translation for id="${correctId}")`,
      description: description ? description : defaultText,
      id: correctId
    };
  }

  static getLanguages() {
    return Object.values(languageMap).filter((v, i, a) => a.indexOf(v) === i);
  }
}

export default LanguageUtils;
