import SsnActionCreators from "../actions/ssnActions";
import SsnActionNames from "../constants/ssnActionNames";
import SsnApi from "../api/ssnApi";
import IAction from "../actions/iAction";
import { put, takeLatest, call } from "redux-saga/effects";
import HttpStatusCodes from "../constants/httpStatusCodes";
import { ErrorTypes } from "../constants/constants";
import ErrorModel from "../models/error";
import UrlUtils from "../lib/urlUtils";

function* sendSmsWithOtp(action: IAction) {
    const phoneNumber = action.payload;
    const { response, error } = yield call(SsnApi.sendSmsWithOtp, phoneNumber);

    if (response) {
        yield put(SsnActionCreators.sendSmsWithOtpSuccess(response.data));
    } else {
        if (error.status === HttpStatusCodes.BADREQUEST) {
            const errorResponse = new ErrorModel();
            errorResponse.type = ErrorTypes.hasAlreadySigned;
            yield put(SsnActionCreators.sendSmsWithOtpError(errorResponse));
        }
        if (error.status === HttpStatusCodes.INTERNAL_SERVER_ERROR) {
            UrlUtils.goto("error");
            const errorResponse = new ErrorModel();
            errorResponse.type = ErrorTypes.internalServerError;
            yield put(SsnActionCreators.sendSmsWithOtpError(errorResponse));
        }
        if (error.status === HttpStatusCodes.CONFLICT) {
            UrlUtils.goto("error");
            const errorResponse = new ErrorModel();
            errorResponse.type = ErrorTypes.conflict;
            yield put(SsnActionCreators.sendSmsWithOtpError(errorResponse));
        }
    }
}

function* logIn(action: IAction) {
    const { otp, privateEmail } = action.payload;
    const { response, error } = yield call(
        SsnApi.logIn, otp, privateEmail
    );

    if (response) {
        yield put(SsnActionCreators.logInSuccess(response.data));
    } else {
        if (error.status === HttpStatusCodes.UNAUTHORIZED) {
            const errorResponse = new ErrorModel();
            errorResponse.type = ErrorTypes.unauthorized;
            yield put(SsnActionCreators.logInError(errorResponse));
        }
    }
}

function* signDocument(action: IAction) {
    const { documentSignInput, authToken } = action.payload;
    const { response, error } = yield call(
        SsnApi.signDocument, documentSignInput, authToken
    );

    if (response) {
        yield put(
            SsnActionCreators.signDocumentSuccess()
        );
    } else {
        if (error.status === HttpStatusCodes.BADREQUEST) {
            UrlUtils.goto("error");
            const errorResponse = new ErrorModel();
            errorResponse.type = ErrorTypes.hasAlreadySigned;
            yield put(SsnActionCreators.signDocumentError(errorResponse));
            
        }
    }
}

export default [
    takeLatest(SsnActionNames.SEND_SMS_WITH_OTP, sendSmsWithOtp),
    takeLatest(SsnActionNames.LOG_IN, logIn),
    takeLatest(SsnActionNames.SIGN_DOCUMENT, signDocument)
];
