export default {
    BADREQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOTFOUND: 404,
    CONFLICT: 409,
    I_AM_A_TEA_POT: 418,
    INTERNAL_SERVER_ERROR: 500,
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
};
