import SsnActionCreators from "../actions/ssnActions";
import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import IApplicationState from "../setup/IApplicationState";
import { Container, Navbar, Nav, Button } from "../common/ReactBootstrapManager";
import LogoSingleBeta from "../img/Logo single beta.svg";
import LoginImage from "../img/First_page 2.svg";
import { injectIntl, FormattedMessage } from "react-intl";
import LanguageUtils from "../lib/languageUtils";
import SSNDto from "../models/ssnDto";
import SsnStep1 from "./ssnStep1";
import SsnStep2 from "./ssnStep2";
import SsnStep3 from "./ssnStep3";
import SsnStep4 from "./ssnStep4";
import LogoBetaDark from "../img/beta_dark_full.svg";
import UrlUtils from "../lib/urlUtils";
import { withRouter, RouteComponentProps } from "react-router";
import IAction from "../actions/iAction";
import ssnSelectors from "../selectors/ssnSelectors";
import DocumentSignDto from "../models/documentSignInputDto";
import AuthToken from "../models/token";
import ErrorModel from "../models/error";
import LoadingWrapper from "../common/loadingWrapper";
import { ErrorTypes, ContactLink } from "../constants/constants";

interface ISSNProps {
  intl: any;
  sendSmsWithOtp: (phoneNumber: string) => IAction;
  logIn: (otp: string, privateEmail: string) => IAction;
  resetError: () => IAction;
  signDocument: (documentSignInput: DocumentSignDto, authToken: AuthToken) => IAction;
  loadingSms: boolean;
  loadingLogin: boolean;
  loadingSignDocument: boolean;
  metadata: SSNDto;
  token: AuthToken;
  errorMessage: ErrorModel;
}

interface ISSNState {
  step: number;
  metadata: SSNDto;
  isValid: boolean;
  hasSignRequest: boolean;
}

interface IData {
  [key: string]: any;
}

type ISSNWithRouterProps = ISSNProps & RouteComponentProps<{}>;

const messages = {
  STEP1_TITLE: LanguageUtils.createMessage("STEP1_TITLE"),
  STEP1_PARAGRAPH: LanguageUtils.createMessage("STEP1_PARAGRAPH"),
  COMPUTAS_LOGO: LanguageUtils.createMessage("COMPUTAS_LOGO"),
  CONTACT_US: LanguageUtils.createMessage("CONTACT_US"),
};


class SSNPage extends React.Component<ISSNWithRouterProps, ISSNState> {
  state = {
    metadata: new SSNDto(),
    step: 1,
    isValid: false,
    hasSignRequest: false,
  };

  componentDidUpdate(prevProps: ISSNWithRouterProps) {
    const { loadingSms, loadingLogin, loadingSignDocument, metadata, errorMessage } = this.props;

    if (
      prevProps.loadingSms === true &&
      loadingSms === false
    ) {
      if (errorMessage.type === ErrorTypes.hasAlreadySigned) {
        this.setState({ step: 2, hasSignRequest: true });
      } else {
        this.setState({ step: 2, metadata: metadata });
      }
    }

    if (prevProps.loadingLogin === true && loadingLogin === false) {
      if (errorMessage.type === "" || errorMessage.type === undefined) {
        this.setState({ step: 3 });
      }
    }

    if (
      prevProps.loadingSignDocument === true &&
      loadingSignDocument === false
    ) {
      this.setState({ step: 4 });
    }
  }

  onChangeStep = (changeStep: number): void => {
    const { location, token, sendSmsWithOtp, logIn, signDocument } = this.props;
    const { metadata } = this.state;
    const params = UrlUtils.getParams(location.search);

    let encodedPhoneNumber = params.token ? params.token : "";
    let phoneNumber = Array.from(encodedPhoneNumber).map(function (v, i) {
      return encodedPhoneNumber.charCodeAt(i) - i - 97;
    }).join('');

    if (changeStep === 2) {
      sendSmsWithOtp(phoneNumber);
    }

    if (changeStep === 3) {
      logIn(metadata.otp, metadata.privateEmail);
    }

    if (changeStep === 4) {
      const documentSignInput = new DocumentSignDto({ issueKey: metadata.jiraKey, candidateSsn: metadata.ssn });
      signDocument(documentSignInput, token);
    }

    if (changeStep === 1) {
      this.setState({ step: 1, isValid: false });
    }
  }

  handleChangeInput = (propName: string, value: string) => {
    const { metadata } = this.state;
    const { errorMessage, resetError } = this.props;

    let newSsnInput = new SSNDto(metadata);
    (newSsnInput as IData)[propName] = value;
    if (errorMessage.type === "" || errorMessage.type === undefined) {
      this.setState({ isValid: true, metadata: newSsnInput });
    } else {
      resetError();
      this.setState({ isValid: false, metadata: newSsnInput });
    }
  }

  renderStep(): JSX.Element {
    const { errorMessage } = this.props;
    const { step, metadata, isValid, hasSignRequest } = this.state;
    switch (step) {
      case 1:
        return (
          <>
            <SsnStep1
              onChangeStep={this.onChangeStep}
            />
          </>
        );
      case 2:
        return <SsnStep2
          onChangeStep={this.onChangeStep}
          metadata={metadata}
          isValid={isValid}
          hasSignRequest={hasSignRequest}
          errorMessage={errorMessage}
          handleChangeInput={
            this.handleChangeInput
          } />;

      case 3:
        return <SsnStep3
          onChangeStep={this.onChangeStep}
          metadata={metadata}
          handleChangeInput={
            this.handleChangeInput
          } />;
      case 4: return <SsnStep4 errorMessage={errorMessage} />;
      default:
        return <span></span>;
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { loadingSms, loadingLogin } = this.props;

    return (
      <React.Fragment>
        <header className="sticky">
          <Navbar
            bg="computas-blue"
            variant="dark"
            role="navigation"
            className="navbarBgDashboard"
            expand="sm"
            aria-controls="responsive-navbar-nav"
          >
            <Container>
              <Navbar.Brand>
                <span className="d-none d-md-inline">
                  <Button
                    variant="link"
                    type="button"
                    className="p-0"
                    onClick={() => this.onChangeStep(1)}
                  >
                    <img
                      alt="logo"
                      src={LogoBetaDark}
                      width="190"
                      height="80"
                      className="d-inline-block align-top mr-1 mt-5"
                      title={formatMessage(messages.COMPUTAS_LOGO)}
                    />
                  </Button>
                </span>
                <span className="d-inline d-md-none">
                  <img
                    alt="logo"
                    src={LogoSingleBeta}
                    width="50"
                    height="35"
                    className="d-inline-block align-top mr-1 mt-2"
                    title={formatMessage(messages.COMPUTAS_LOGO)}
                  />
                </span>
              </Navbar.Brand>

              <Nav className="justify-content-end flex-row">
                <div className="text-login logout mt-2">
                  <a
                    href={`mailto:${ContactLink.contactMail}`}
                  >
                    <FormattedMessage {...messages.CONTACT_US} />
                  </a>
                </div>
              </Nav>
            </Container>
          </Navbar>
        </header>
        <LoadingWrapper
          loading={
            loadingSms ||
            loadingLogin
          }
          size={70}
          className="wrapperDashboard d-flex align-items-center align-self-center align-content-center justify-content-center"
        >
          {this.renderStep()}
        </LoadingWrapper>
        <section>
          <div className="bgLarge d-none d-lg-inline">
            <img className="w-100" src={LoginImage} alt="loginImage" />
          </div>
          <div className="bgSmall d-inline d-lg-none">
            <img className="w-100" src={LoginImage} alt="loginImage" />
          </div>
        </section>
      </React.Fragment>
    );
  }

}
const mapStoreToProps = (store: IApplicationState) => {
  return {
    metadata: ssnSelectors.jiraMetadata(store),
    loadingSms: ssnSelectors.loadingSms(store),
    loadingLogin: ssnSelectors.loadingLogin(store),
    loadingSignDocument: ssnSelectors.loadingSignDocument(store),
    token: ssnSelectors.token(store),
    errorMessage: ssnSelectors.errorMessage(store),
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendSmsWithOtp: (phoneNumber: string) =>
    dispatch(SsnActionCreators.sendSmsWithOtp(phoneNumber)),
  logIn: (otp: string, privateEmail: string) =>
    dispatch(SsnActionCreators.logIn(otp, privateEmail)),
  signDocument: (documentSignInput: DocumentSignDto, authToken: AuthToken) =>
    dispatch(SsnActionCreators.signDocument(documentSignInput, authToken)),
  resetError: () => dispatch(SsnActionCreators.resetError()),
});

export default withRouter(injectIntl(
  connect(mapStoreToProps, mapDispatchToProps)(SSNPage))
);
