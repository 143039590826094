import React, { Component } from "react";
import { Container } from "../common/ReactBootstrapManager";
import { FormattedMessage } from "react-intl";
import LanguageUtils from "../lib/languageUtils";
import ErrorPage from "./error/errorPage";
import ErrorModel from "../models/error";
import { ErrorTypes } from "../constants/constants";

interface ISsnStep4Props {
	errorMessage: ErrorModel;
}
const messages = {
	STEP4_TITLE: LanguageUtils.createMessage("STEP4_TITLE"),
	STEP4_PARAGRAPH: LanguageUtils.createMessage("STEP4_PARAGRAPH")
};

class SsnStep4 extends Component<ISsnStep4Props, any> {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		const { errorMessage } = this.props;
		const generalErrors = errorMessage.type === ErrorTypes.hasAlreadySigned;
		return (
			<Container fluid className="headerBgBlue maxWidth">
				{generalErrors ? (
					<ErrorPage />
				) : (
						<section>
							<h2 className="headings-title font-weight-bold justify-content-center text-center pt-4 pb-3">
								<FormattedMessage {...messages.STEP4_TITLE} />
							</h2>

							<p className="login-parag pt-3 pb-3">
								<FormattedMessage {...messages.STEP4_PARAGRAPH} />
							</p>
						</section>
					)}
			</Container>
		);
	}
}

export default (SsnStep4);
