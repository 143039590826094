import { Map } from "immutable";
import IImmutableConfigReducer from "./interfaces/immutable/iImmutableConfigReducer";
import IAction from "../actions/iAction";
import configActionConstants from "../constants/configActionConstants";

const initialState = Map({
    isLoaded: false
}) as IImmutableConfigReducer;

const ConfigReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case configActionConstants.FETCH_CONFIG_SUCCESS: {
            return state.set("isLoaded", true);
        }

        default: {
            return state;
        }

    }
};
export default ConfigReducer;

