class SSNDto {
  firstName: string;
  lastName: string;
  jiraKey: string;
  privateEmail: string;
  otp: string;
  ssn: string;

  constructor(
    ssnDto: {
      firstName: string;
      lastName: string;
      jiraKey: string;
      privateEmail: string;
      otp: string;
      ssn: string;
    } = {
        firstName: "",
        lastName: "",
        jiraKey: "",
        privateEmail: "" ,
        otp: "",
        ssn: ""
      }
  ) {
    this.firstName = ssnDto.firstName;
    this.lastName = ssnDto.lastName;
    this.jiraKey = ssnDto.jiraKey;
    this.privateEmail = ssnDto.privateEmail || "";
    this.otp = ssnDto.otp;
    this.ssn = ssnDto.ssn || "";
  }
}
export default SSNDto;
