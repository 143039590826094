import ConfigSagas from "../sagas/configSagas";
import { fork, take, cancel, all } from "redux-saga/effects";
import SsnSagas from "../sagas/ssnSagas";

const CANCEL_SAGAS_HMR = "CANCEL_SAGAS_HMR";

function* rootSaga() {
    yield all([
        ...ConfigSagas,
        ...SsnSagas,
    ]);
}


function createAbortableSaga(saga: any) {
    if (process.env.NODE_ENV === "development") {
        return function* main() {
            const sagaTask = yield fork(saga);
            yield take(CANCEL_SAGAS_HMR);
            yield cancel(sagaTask);
        };
    }

    return saga;
}

const SagaManager = {
    startSaga(sagaMiddleware: any) {
        const rootTask = sagaMiddleware.run(createAbortableSaga(rootSaga));

        rootTask.toPromise().catch((err: any) => {
            console.log("Error in Sagas", err); //eslint-disable-line
        });

        return rootTask;
    },

    cancelSaga(store: any) {
        store.dispatch({ type: CANCEL_SAGAS_HMR });
    }
};

export default SagaManager;