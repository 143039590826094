import AuthToken from "../models/token";
import Axios, { CancelToken } from "axios";
import Config from "./config";
import Error from "../models/error";
import HttpStatusCodes from "../constants/httpStatusCodes";
import HttpVerbs from "../constants/httpVerbs";
import LocalStorageConstants from "../browser/localStorageConstants";
import LocalStorageUtils from "../utils/localStorageUtils";
import ToasterActionCreators from "../actions/toasterActions";
import SsnActionCreators from "../actions/ssnActions";
import UrlUtils from "./urlUtils";
import urlTemplate from "url-template";
import { ToasterMessageType } from "../constants/toasterMessageType";
import { ErrorTypes } from "../constants/constants";

interface Options {
  data?: object;
  options?: object;
  cancelToken?: CancelToken;
  includeBase?: boolean;
  token?: AuthToken;
}

class Api {
  static get(
    url: string,
    params?: object,
    {
      data = undefined,
      options = undefined,
      includeBase = false,
      token = undefined,
      cancelToken
    }: Options = {}
  ) {
    return this._do(HttpVerbs.GET, url, params, {
      data,
      options,
      includeBase,
      cancelToken,
      token,
    });
  }

  static put(
    url: string,
    params?: object,
    {
      data = undefined,
      options = undefined,
      includeBase = false,
      cancelToken = undefined,
      token = undefined,
    }: Options = {}
  ) {
    return this._do(HttpVerbs.PUT, url, params, {
      data,
      options,
      includeBase,
      cancelToken,
      token
    });
  }

  static post(
    url: string,
    params?: object,

    {
      data = undefined,
      options = undefined,
      includeBase = false,
      cancelToken = undefined,
      token = undefined,
    }: Options = {}
  ) {
    return this._do(HttpVerbs.POST, url, params, {
      data,
      options,
      includeBase,
      cancelToken,
      token
    });
  }

  static delete(
    url: string,
    params?: object,
    { data = undefined, options = undefined, includeBase = false }: Options = {}
  ) {
    return this._do(HttpVerbs.DELETE, url, params, {
      data,
      options,
      includeBase
    });
  }

  static async _do(
    verb: string,
    url: string,
    params?: object,
    {
      data = undefined,
      options = undefined,
      includeBase = false,
      cancelToken = undefined,
      token = undefined,
    }: Options = {}
  ) {
    const urlWithBase = includeBase ? url : `${Config.getServerUrl}/${url}`;
    const authToken = token || LocalStorageUtils.getItem(
      LocalStorageConstants.AUTH_TOKEN
    ) as AuthToken;

    const authorization =
      authToken && authToken.accessToken
        ? `Bearer ${authToken.accessToken}`
        : "";
    const defaultConfig = {
      method: verb,
      url: this._composeUrl(urlWithBase, params),
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      cancelToken,
    };

    try {
      console.log(this._composeOptions(defaultConfig, options));
      const response = await Axios(
        this._composeOptions(defaultConfig, options)
      );
      return { response };
    } catch (errorResponse) {
      const error = this._handleError(errorResponse);
      return { error };
    }
  }

  static _composeOptions(defaultOptions: object, newOptions?: object) {
    return { ...defaultOptions, ...newOptions };
  }

  static _handleError(errorResponse: object) {
    const error = Error.MapFromErrorResponse(errorResponse);
    return error;
  }

  static _composeUrl(url: string, params?: object): string {
    if (!params) {
      return url;
    }

    const urlExpander = urlTemplate.parse(url);

    return urlExpander.expand(params);
  }
}

export default Api;
