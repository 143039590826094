import ConfigReducer from "../reducers/configReducer";
import IAction from "../actions/iAction";
import ToasterReducer from "../reducers/toasterReducer";
import { combineReducers } from "redux-immutable";
import SsnReducer from "../reducers/ssnReducer";

const initReducer = () => {
  return combineReducers({
    toaster: ToasterReducer,
    config: ConfigReducer,
    jiraMetadata: SsnReducer,
  });
};
const appReducer = initReducer();

const reducers = (state: any, action: IAction) => {
  return appReducer(state, action);
};

export default reducers;
