import { DisplayOptions } from "../constants/constants";
import HttpStatusCodes from "../constants/httpStatusCodes";

class Error {
    status?: number;
    statusText?: string;
    displayOption?: number;
    message?: string;
    displayMessage?: string;
    type?: string;

    constructor(status?: number, statusText?: string, message?: string, displayOption?: number, displayMessage?: string, type?: string) {
        this.status = status;
        this.statusText = statusText;
        this.message = message;
        this.displayOption = displayOption;
        this.displayMessage = displayMessage;
        this.type = type;
    }

    static MapFromErrorResponse(error: any): Error {
        if (error.response) {
            const errorResponse = error.response;
            const message = `Error, status code:${errorResponse.status}, statusText:${errorResponse.statusText}, message: ${errorResponse.data !== null ? errorResponse.data.Message : ""}`;
            const displayOption = errorResponse.data !== null ? errorResponse.data.DisplayOption : DisplayOptions.Hidden;
            let displayMessage = "";

            // if (errorResponse.status === HttpStatusCodes.UNAUTHORIZED) {
            //     displayMessage = "Authorization failed. Please sign in again";
            //} 
            if (errorResponse.status === HttpStatusCodes.FORBIDDEN) {
                displayMessage = "Permission denied.";
            } else if (displayOption === DisplayOptions.Shown) {
                displayMessage = errorResponse.data.Message;
            } else if (!(errorResponse.status === HttpStatusCodes.BADREQUEST && errorResponse.data && errorResponse.data.error === "invalid_grant")) {
                displayMessage = "An error occurred while processing your request. Please contact the system administrator";
            } else if (errorResponse.status === HttpStatusCodes.BADREQUEST && errorResponse.data && errorResponse.data.error === "invalid_grant") {
                displayMessage = "Authentication failed!";
            }

            return new Error(errorResponse.status, errorResponse.statusText, message, displayOption, displayMessage);
        }

        return new Error(HttpStatusCodes.I_AM_A_TEA_POT, "ERR_EMPTY_RESPONSE", error.message, DisplayOptions.Hidden, error.message);
    }
}

export default Error;
