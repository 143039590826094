export const DisplayOptions = {
    Shown: 1,
    Hidden: 2
};

export const ErrorTypes = {
    unauthorized: "unauthorized",
    hasAlreadySigned: "hasAlreadySigned",
    internalServerError: "internalServerError",
    conflict: "conflict",
    badRequest: "badRequest",
};

export const ContactLink = {
    contactMail: "onboarding@computas.com"
}
