import Api from "../lib/axiosApi";
import { sendSmsWithOtpUrl, logInUrl, signDocumentUrl } from "../constants/urls";
import DocumentSignInputDto from "../models/documentSignInputDto";
import AuthToken from "../models/token";

const sendSmsWithOtp = (phoneNumber: string) => {
  const data = new FormData();
  data.append("phoneNumber",  phoneNumber);
  return Api.post(sendSmsWithOtpUrl, undefined, { data });
};

const logIn = (otp: string, privateEmail: string) => {
  const data = new FormData();
  data.append("otp", otp);
  data.append("privateEmail", privateEmail);
  return Api.post(logInUrl, undefined, { data });
};

const signDocument = (documentSignInput: DocumentSignInputDto, token: AuthToken) => {
  return Api.post(signDocumentUrl, undefined, { data: documentSignInput, token });
}

export default { sendSmsWithOtp, logIn, signDocument };